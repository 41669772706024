import {Controller} from "stimulus";

export default class extends Controller {
  static values = {
    trigger:        String,
    event:          String,
    currency:       String,
    itemId:         String,
    itemName:       String,
    itemBrand:      String,
    itemCategory:   String,
    price:          Number,
    quantity:       Number,
    transactionId:  String,
    value:          Number,
    tax:            Number,
    shipping:       Number,
    coupon:         String,
    customerId:     String,
    discount:       Number
  }

  connect() {
    const trigger = this.triggerValue;

    if (trigger === "pageload") {
      this.pushToDataLayer();
    } else if (trigger === "click") {
      this.element.addEventListener("click", (event) => {
        event.preventDefault();

        const addToCartEvent = this.element.dataset.ga4EcommerceEventValue === 'add_to_cart';
        const form = this.element.closest('form');
        let quantityInput = 0;
        if (form) {
          quantityInput = form.querySelector('.qa-quantity input');
        } else {
          quantityInput = this.element.dataset.ga4EcommerceQuantityValue;
        }

        if (addToCartEvent) {
          this.quantityValue = quantityInput.value;
          this.pushToDataLayer();
        } else {
          this.pushToDataLayer();
        }

        // Check if the form is an XHR form
        if (form && form.dataset.remote === "true") {
          // Allow the XHR request to proceed
          form.submit();
        } else if (form) {
          // Submit the form normally
          event.target.closest('form').submit();
        }
      });
    }

    if (this.element.dataset.ga4EcommerceEventValue === 'view_cart') {
      this.viewCart();
    }
  }

  pushToDataLayer() {

    const event = this.getValue('event');
    const currency = this.getValue('currency');
    const itemId = this.getValue('itemId');
    const itemName = this.getValue('itemName');
    const itemBrand = this.getValue('itemBrand');
    const itemCategory = this.getValue('itemCategory');
    const price = this.getValue('price');
    const quantity = this.getValue('quantity');
    const transactionId = this.getValue('transactionId');
    const value = this.getValue('value');
    const tax = this.getValue('tax');
    const shipping = this.getValue('shipping');
    const coupon = this.getValue('coupon');
    const customerId = this.getValue('customerId');
    const discount = this.getValue('discount');

    const ecommerceData = {
      currency: currency,
      customer_id: customerId,
      items: []
    };

    if (itemId || itemName || itemBrand || itemCategory || !isNaN(price) || !isNaN(quantity)) {
      ecommerceData.items.push({
        item_id: itemId,
        item_name: itemName,
        item_brand: itemBrand,
        item_category: itemCategory,
        price: !isNaN(price) ? price : undefined,
        quantity: !isNaN(quantity) ? quantity : undefined,
        discount: !isNaN(discount) ? discount : undefined
      });
    }

    if (transactionId) ecommerceData.transaction_id = transactionId;
    if (!isNaN(value)) ecommerceData.value = value;
    if (!isNaN(tax)) ecommerceData.tax = tax;
    if (!isNaN(shipping)) ecommerceData.shipping = shipping;
    if (coupon) ecommerceData.coupon = coupon;
    if (customerId) ecommerceData.customer_id = customerId;

    const dataLayerEvent = {
      event: event,
      ecommerce: ecommerceData
    };

    window.dataLayer.push({ ecommerce: null }); // Clear the ecommerce object
    window.dataLayer.push(dataLayerEvent);
  }

  viewCart() {
    const cartItems = JSON.parse(this.element.dataset.cartItems);
    const currency = this.getValue('currency');
    const value = this.getValue('value');
    const customerId = this.getValue('customerId');
    const shipping = this.getValue('shipping');

    const ecommerceData = {
      currency: currency,
      value: value,
      items: cartItems
    };

    if (customerId) ecommerceData.customer_id = customerId;
    if (!isNaN(shipping)) ecommerceData.shipping = shipping;

    const dataLayerEvent = {
      event: 'view_cart',
      ecommerce: ecommerceData
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayerEvent);
  }

  // Get explicit set value set. If not set, return undefined.
  // This is a workaround for Stimulus not supporting disabling of default values.
  // undefined values are not pushed to the dataLayer.
  getValue(key) {
    const hasKey = `has${key.charAt(0).toUpperCase() + key.slice(1)}Value`;
    const keyVal = `${key}Value`;

    return this[hasKey] ? this[keyVal] : undefined;
  }
}
